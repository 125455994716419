import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledContactBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [50, 100]
  })
)

export const styledContact = css`
  ${styledContactBps};
`

/* HEADER */
export const H3HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 416, 497],
    marginBottom: [16],
    fontSize: [33.6, 33.6, 33.6, 47.9],
    fontWeight: [600, 600, 600, 'normal'],
    textAlign: ['center', 'center', 'left', 'left']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 416, 422],
    textAlign: ['center', 'center', 'left', 'left'],
    marginBottom: [40, 30, 0, 0]
  })
)

export const contactInfoBps = css(
  mq({
    marginLeft: [0, 0, 114, 216],
    marginRight: [0, 0, 114, 216]
  })
)

export const contactFormBps = css(
  mq({
    marginLeft: [0, 0, 0, 0]
  })
)

export const imgBps = css(
  mq({
    maxWidth: ['75%', '50%', '60%', '50%'],
    marginLeft: [0, 0, 144, 210],
    margin: ['0 auto'],
    justifyContent: ['center', 'center', 'left', 'left']
  })
)

export const mapBps = css(
  mq({
    display: ['flex', 'flex', 'none', 'none'],
    width: [328, 422, 0, 0]
  })
)

export const header = css`
  display: flex;
  text-align: center;
  h3 {
    ${H3HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PHeaderBps};
  }
  span {
    font-size: 15.7px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.53;
    letter-spacing: 0.15px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
  }
`

export const contactInfo = css`
  display: flex;
  ${contactInfoBps};
`

export const contactItem = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  h4 {
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 16px;
  }
  p {
    font-size: 13.8px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.74;
    letter-spacing: 0.1px;
  }
`
export const imagem = css`
  ${imgBps};
  display: flex;
`

export const contactForm = css`
  ${contactFormBps};
`

export const googleMap = css`
  img {
    ${mapBps};
    margin-top: 24px;
  }
`
export const termosBox = css`
  font-size: 11.8px !important;
  font-weight: 600 !important;
  line-height: 1.36 !important;
  color: rgba(0,0,0,0.6) !important;
  text-align: left !important;
  text-transform: none !important;
  letter-spacing: 0px !important;
  a {
    text-decoration: none !important;
    color: #ff5800 !important;
  }
  a:hover {
    text-decoration: underline !important;
    font-weight: bold;
  }
`
export const termosBoxWhite = css`
  font-size: 11.8px !important;
  font-weight: 600 !important;
  line-height: 1.36 !important;
  color: rgba(255,255,255,0.7) !important;
  text-align: left !important;
  text-transform: none !important;
  letter-spacing: 0px !important;
  a {
    color: #fff !important;
    text-decoration: none !important;
  }
  a:hover {
    text-decoration: underline !important;
  }
`