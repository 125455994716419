import React, { useState, useEffect } from 'react'
import { Field, withFormik } from 'formik'
import axios from 'axios'
import { withStyles } from '@material-ui/core/styles'
import Check from '@material-ui/icons/Check'
import Error from '@material-ui/icons/Error'
import CircularProgress from '@material-ui/core/CircularProgress';

import MuiField from './../components/MuiField'
import { btnLarge } from '../components/styles/ContainedButton.styles'
import MuiSelect from '../components/Dropdown'
import Modal from './Modal'
import H6 from './H6'

const initialModals = {
  support: false,
  moreInfo: false,
  success: false,
  error: false
}

const Loader = withStyles({
  root: {
    color: '#FFFFFF'
  }
})(CircularProgress)

const SuccessModal = ({ visible, onClose }) => {
  return (
    <Modal open={visible} onClose={onClose}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 30
        }}
      >
        <div
          style={{
            width: 50,
            height: 50,
            backgroundColor: '#7ed321',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Check
            style={{
              color: 'white',
              flexShrink: 0,
              fontSize: 36
            }}
          />
        </div>
      </div>
      <H6
        selected
        on='onSurface'
        variant='highEmphasis'
        align='center'
        mb={[5]}
      >
        Agradecemos seu<br />
        contato! Retornaremos<br />
        em breve.
      </H6>
    </Modal>
  )
}

const ErrorModal = ({ visible, onClose }) => {
  return (
    <Modal open={visible} onClose={onClose}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 30
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Error
            style={{
              color: '#b00020',
              flexShrink: 0,
              fontSize: 50
            }}
          />
        </div>
      </div>
      <H6
        selected
        on='onSurface'
        variant='highEmphasis'
        align='center'
        mb={[5]}
      >
        Desculpe-nos,<br /> 
        não foi possível<br /> 
        enviar o formulário.<br />
        Tente novamente.
      </H6>
    </Modal>
  )
}

const FormikField = ({ field, form, ...rest }) => {
  return <MuiField {...field} {...rest} />
}

const FormikSelect = ({ field, form, options, label, ...rest }) => {
  return (
    <MuiSelect
      options={options}
      label={label}
      value={options.find(option => option.value === field.value)}
      onChange={value => {
        form.setFieldValue(field.name, value.value)
      }}
      isClearable={false}
      {...rest}
    />
  )
}

const validate = value => {
  let error
  if (!value) {
    error = 'Campo Obrigatório'
  }
  return error
}

const ContactForm = ({
  fields,
  values,
  isSubmitting,
  isValid,
  errors,
  submitForm,
  submitText,
  status,
}) => {
  const [modals, setModals] = useState(initialModals)

  const setModalVisibility = modal => visible => {
    setModals({
      ...modals,
      [modal]: visible
    })
  }

  useEffect(() => {
    if (status === 'submitted') {
      setModalVisibility('success')(true)
    }

    if (status === 'error') {
      setModalVisibility('error')(true)
    }

    return () => {
      setModalVisibility('success')(false)
      setModalVisibility('error')(false)
    }
  }, [status])

  return (
    <>
      {fields.map((field, i) => {
        if (typeof field.render === 'function') {
          return field.render(fields)
        }

        if (field.type === 'select') {
          return (
            <Field
              key={`contact-field-${i}`}
              label={field.label}
              name={field.name}
              placeholder={field.label}
              component={FormikSelect}
              options={field.options}
              validate={field.required && validate}
              multiline={field.multiline}
              rows={field.rows}
              mask={field.mask}
              style={{
                width: 328,
                marginBottom: 10,
                textAlign: 'left'
              }}
            />
          )
        }

        return (
          <Field
            key={`contact-field-${i}`}
            variant='outlined'
            label={field.label}
            name={field.name}
            placeholder={field.label}
            component={FormikField}
            validate={field.required && validate}
            multiline={field.multiline}
            rows={field.rows}
            mask={field.mask}
            style={{
              maxWidth: 328,
              marginBottom: 10
            }}
          />
        )
      })}
      <div
        disabled={isSubmitting || !isValid}
        onClick={() => submitForm()}
        css={btnLarge}
        style={{
          marginTop: 14,
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {!isSubmitting ? submitText : <Loader size={14} />}
      </div>
      <SuccessModal
        visible={modals.success}
        onClose={() => setModalVisibility('success')(false)}
      />
      <ErrorModal
        visible={modals.error}
        onClose={() => setModalVisibility('error')(false)}
      />
    </>
  )
}

ContactForm.defaultProps = {
  submitText: 'Enviar',
}

export default withFormik({
  mapPropsToValues: () => ({}),
  handleSubmit: async (values, { setSubmitting, setStatus, props }) => {
    setStatus('unsubmitted')
    try {
      const formData = new FormData()
      Object.keys(values).forEach(key => {
        formData.set(key, values[key])
      })
      const response = await axios
        .post(`https://hostdime.com.br/wp/wp-json/contact-form-7/v1/contact-forms/${props.formId}/feedback`, formData)

      console.log(response.data)
      if (response.data.status === 'validation_failed') {
        throw new Error('Formulário inválido.')
      }

      
      await new Promise(resolve => setTimeout(() => resolve(), 2000)) // TODO REMOVE


      setStatus('submitted')
      setSubmitting(false)
    } catch (e) {
      console.error(e)
      setStatus('error')
      setSubmitting(false)
    }
  }
})(ContactForm)
